import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Carousel, Seo } from '../elements';
import { ContentContext } from '../context';
import API from '../api';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselSlides: [],
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems = async () => {
    this.setState({
      isLoading: true,
    });

    await API.get('', {
      params: {
        action: 'slider',
      },
    })
      .then((response) => {
        this.setState({
          carouselSlides: response.data,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { carouselSlides } = this.state;

    return (
      <ContentContext.Consumer>
        {({ getContent }) => (
          <div className='page__content'>
            <Seo title='' />
            <Carousel slides={carouselSlides} />
            <div className='services'>
              <div className='services__item'>
                <NavLink to='/culinary-arts-programs/'>
                  <img
                    alt=''
                    className='services__item__photo'
                    src='/temp/images/home_more_01.png'
                  />
                </NavLink>
                <h2>
                  <NavLink to='/culinary-arts-programs/'>
                    {getContent('homepageProgram').title}
                  </NavLink>
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getContent('homepageProgram').content,
                  }}
                />
                <p className='cta'>
                  <NavLink className='button' to='/culinary-arts-programs/'>
                    Read More
                  </NavLink>
                </p>
              </div>
              <div className='services__item'>
                <NavLink to='/classes/'>
                  <img
                    alt=''
                    className='services__item__photo'
                    src='/temp/images/home_more_02.png'
                  />
                </NavLink>
                <h2>
                  <NavLink to='/classes/'>
                    {getContent('homepageClasses').title}
                  </NavLink>
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getContent('homepageClasses').content,
                  }}
                />
                <p className='cta'>
                  <NavLink className='button' to='/classes/'>
                    Read More
                  </NavLink>
                </p>
              </div>
              <div className='services__item'>
                <NavLink to='/private-events/'>
                  <img
                    alt=''
                    className='services__item__photo'
                    src='/temp/images/home_more_03.png'
                  />
                </NavLink>
                <h2>
                  <NavLink to='/private-events/'>
                    {getContent('homepageEvents').title}
                  </NavLink>
                </h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getContent('homepageEvents').content,
                  }}
                />
                <p className='cta'>
                  <NavLink className='button' to='/private-events/'>
                    Read More
                  </NavLink>
                </p>
              </div>
            </div>
            <div className='about'>
              <h2>
                <NavLink to='/about-us/'>
                  {getContent('homepageAbout').title}
                </NavLink>
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: getContent('homepageAbout').content,
                }}
              />
              <p>
                <NavLink className='button' to='/about-us/'>
                  Read More
                </NavLink>
              </p>
            </div>
          </div>
        )}
      </ContentContext.Consumer>
    );
  }
}
