import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import {
  BookingButton,
  CoverPage,
  FormInputBlock,
  Loading,
  Seo,
} from '../elements';
import { ContentContext } from '../context';
import API from '../api';

export default class Details extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: null,
      category: null,
      dates: null,
      days: [],
      toPay: null,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      selectedEvent: null,
    };

    this.externalBookingRef = React.createRef();
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (
      typeof DROPLET === 'object' &&
      this.externalBookingRef.id &&
      this.state.product &&
      this.state.product.externalBookingId
    ) {
      new window.DROPLET.Widget({
        host: 'my420tours.checkfront.com',
        target: 'CHECKFRONT_WIDGET_01',
        item_id: this.state.product.externalBookingId,
        category_id: '5',
        tid: 'cook-st-website',
        options: 'tabs,hidedates',
        provider: 'droplet',
      }).render();
    }
  }

  loadData = async () => {
    const { slug } = this.props.match.params;

    let product = {};

    await API.get('', {
      params: {
        action: 'classDetails',
        slug: slug,
      },
    })
      .then((response) => {
        product = response.data;

        this.setState({
          product: product,
        });
      })
      .catch((error) => {});

    let category = JSON.parse(localStorage.getItem('category'));

    if (!category) {
      category = product.categories[0];
    } else {
      let foundCategory = false;

      for (let i in product.categories) {
        if (category.id === product.categories[i].id) {
          foundCategory = true;
          break;
        }
      }

      if (!foundCategory) {
        category = product.categories[0];
      }
    }

    let dates = null;
    let days = this.state.days;
    let selectedEvent = null;
    let firstSeriesEvent = null;
    let otherSeriesEvents = [];

    if (product.events.length) {
      dates = [];
      for (let i in product.events) {
        if (product.events[i].type === 'series-first') {
          firstSeriesEvent = i;
        }

        if (product.events[i].type === 'series' && firstSeriesEvent === null) {
          delete product.events[i];
          continue;
        }

        dates.push(moment.utc(product.events[i].startsAt).toDate());
        days.push(moment.utc(product.events[i].startsAt).format('YYYY-MM-DD'));

        if (
          selectedEvent === null &&
          product.events[i].capacity - product.events[i].bookingCount > 0
        ) {
          selectedEvent = product.events[i];
        }

        if (product.events[i].type === 'series') {
          otherSeriesEvents.push(i);

          if (firstSeriesEvent) {
            if (!product.events[firstSeriesEvent].events) {
              product.events[firstSeriesEvent].events = [];
            }

            product.events[firstSeriesEvent].events.push(product.events[i]);
          }
        }
      }

      if (otherSeriesEvents.length) {
        let newEvents = [];

        for (let i in product.events) {
          if (otherSeriesEvents.indexOf(i) === -1) {
            newEvents.push(product.events[i]);
          }
        }

        product.events = newEvents;
      }
    }

    this.setState({
      category: category,
      dates: dates,
      selectedEvent: selectedEvent,
    });
  };

  _calendarDateIsActive = (date, view) => {
    return this.state.days.indexOf(moment.utc(date).format('YYYY-MM-DD')) >= 0
      ? 'active'
      : '';
  };

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _eventDateOnClick = (event) => {
    if (event.soldOut) {
      return false;
    }

    this.setState({
      selectedEvent: event,
    });
  };

  _formOnSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    await API.post('', {
      action: 'classReminderSubmit',
      classId: this.state.product.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
    })
      .then((response) => {})
      .catch((error) => {});

    await API.post('', {
      action: 'classInquiry',
      formItems: [
        {
          title: 'Class',
          value: this.state.product.title,
        },
        {
          title: 'First Name',
          value: this.state.firstName,
        },
        {
          title: 'Last Name',
          value: this.state.lastName,
        },
        {
          title: 'Email',
          value: this.state.email,
        },
        {
          title: 'Phone',
          value: this.state.phone,
        },
      ],
    })
      .then((response) => {
        this.setState({
          isSubmitting: false,
          isSubmitted: true,
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isSubmitting: false,
        });
      });
  };

  render() {
    const {
      category,
      product,
      isSubmitted,
      isSubmitting,
      selectedEvent,
      toPay,
    } = this.state;

    if (!category) {
      return <Loading />;
    }

    if (toPay) {
      return <Redirect to='/pay' />;
    }

    return (
      <ContentContext.Consumer>
        {({ getContent }) => (
          <CoverPage
            title={product.title}
            coverPhoto={product.largePhoto}
            search={true}
            breadcrumbs={[
              {
                label: 'Classes',
                link: '/classes',
              },
              {
                label: category.title,
                link: `/product-category/${category.slug}/`,
              },
              {
                label: product.title,
              },
            ]}
          >
            <Seo title={product.title} />
            {product.externalBookingId && (
              <div
                ref={(ref) => (this.externalBookingRef = ref)}
                id='CHECKFRONT_WIDGET_01'
              >
                <p
                  id='CHECKFRONT_LOADER'
                  style={{
                    background:
                      'url(//my420tours.checkfront.com/images/loader.gif) left center no-repeat',
                    padding: '5px 5px 5px 20px',
                  }}
                >
                  Searching Availability...
                </p>
              </div>
            )}
            {!product.externalBookingId && (
              <div className='details'>
                <div className='details__info'>
                  <div className='book'>
                    <h2 className='book__title'>Book This Class</h2>
                    <div className='book__price'>
                      <span>${product.price}</span>.00
                    </div>
                    <div className='book__info'>
                      Price is per person, not per couple.
                    </div>
                    {selectedEvent !== null && (
                      <>
                        <div className='book__calendar'>
                          <Calendar
                            activeStartDate={moment
                              .utc(selectedEvent.startsAt)
                              .toDate()}
                            calendarType='US'
                            tileClassName={({ date, view }) =>
                              this._calendarDateIsActive(date, view)
                            }
                          />
                        </div>
                        <div className='book__events'>
                          {product.events.map((event, key) => (
                            <>
                              {event.type !== 'series' && (
                                <div
                                  key={key}
                                  className={`book__event${
                                    event.id === selectedEvent.id
                                      ? ' book__event--selected'
                                      : ''
                                  }${
                                    event.soldOut ? ' book__event--soldout' : ''
                                  }`}
                                  onClick={() => this._eventDateOnClick(event)}
                                >
                                  <div className='book__event__date'>
                                    {moment
                                      .utc(event.startsAt)
                                      .format('MMM Do')}
                                  </div>
                                  <div className='book__event__time'>
                                    {moment.utc(event.startsAt).format('h:mma')}
                                  </div>
                                  <div className='book__event__slots'>
                                    {!event.soldOut && (
                                      <>
                                        {event.capacity - event.bookingCount}{' '}
                                        left
                                      </>
                                    )}
                                    {event.soldOut && <>Sold out</>}
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        </div>
                        {selectedEvent.online ? (
                          <div className='book__online'>
                            <div className='book__online__option'>
                              <p className='book__online__option__copy'>
                                Ingredient Kit Included
                              </p>
                              <BookingButton
                                label='Book Now'
                                event={selectedEvent}
                                history={this.props.history}
                              />
                            </div>
                            <div className='book__online__option'>
                              <p className='book__online__option__copy'>
                                No Ingredient Kit Included
                              </p>
                              <BookingButton
                                label='Book Now'
                                event={selectedEvent}
                                history={this.props.history}
                                online={true}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='book__cta'>
                            <BookingButton
                              label='Book Now'
                              event={selectedEvent}
                              history={this.props.history}
                            />
                          </div>
                        )}
                        <div className='gift__cta'>
                          <NavLink
                            className='link'
                            to={`/cook-street-gift-cards/${product.slug}/`}
                          >
                            Gift this Class
                          </NavLink>
                          <br />
                          <p style={{ fontSize: '0.8rem', marginTop: '5px' }}>
                            ** This does not automatically register the person
                            for the class
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className='details__content'>
                  <article className='article'>
                    {product.menu && (
                      <div className='menu'>
                        <h2>Menu</h2>
                        <div
                          dangerouslySetInnerHTML={{ __html: product.menu }}
                        />
                      </div>
                    )}

                    <div>
                      <p>{product.intro}</p>
                    </div>

                    <div
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />

                    <div className='upcoming__dates'>
                      <h2>Upcoming Dates</h2>

                      {product.events.length > 0 && (
                        <ul className='event__list'>
                          {product.events.map((event, key) => (
                            <li key={key} className='event__list__item'>
                              <span
                                className={
                                  event.soldOut
                                    ? 'event__list__item--soldout'
                                    : ''
                                }
                              >
                                {moment
                                  .utc(event.startsAt)
                                  .format('MMMM Do @ h:mma')}{' '}
                                - {moment.utc(event.endsAt).format('h:mma')}
                              </span>
                              {event.soldOut && (
                                <>
                                  <i>sold out</i>
                                </>
                              )}
                              {event.events && (
                                <ul>
                                  {event.events.map((item, key) => (
                                    <li key={key}>
                                      {moment
                                        .utc(item.startsAt)
                                        .format('MMMM Do @ h:mma')}{' '}
                                      -{' '}
                                      {moment.utc(item.endsAt).format('h:mma')}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                      {(product.events.length === 0 || !selectedEvent) && (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getContent('classInquiry').content,
                            }}
                          />
                          {isSubmitted && (
                            <div className='form'>
                              <h3>
                                {getContent('classInquiryThankyou').title}
                              </h3>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: getContent('classInquiryThankyou')
                                    .content,
                                }}
                              />
                            </div>
                          )}
                          {!isSubmitted && (
                            <>
                              <form
                                className='form'
                                onSubmit={this._formOnSubmit}
                              >
                                <FormInputBlock
                                  name='firstName'
                                  label='First Name'
                                  value={this.state.firstName}
                                  required={true}
                                  onChange={this._inputOnChange}
                                />
                                <FormInputBlock
                                  name='lastName'
                                  label='Last Name'
                                  value={this.state.lastName}
                                  required={true}
                                  onChange={this._inputOnChange}
                                />
                                <FormInputBlock
                                  name='email'
                                  label='Email'
                                  type='email'
                                  value={this.state.email}
                                  required={true}
                                  onChange={this._inputOnChange}
                                />
                                <FormInputBlock
                                  name='phone'
                                  label='Phone'
                                  value={this.state.phone}
                                  onChange={this._inputOnChange}
                                />
                                <fieldset className='fieldset'>
                                  <button
                                    disabled={isSubmitting}
                                    className='button'
                                  >
                                    {isSubmitting
                                      ? 'Please wait...'
                                      : 'Notify Me'}
                                  </button>
                                </fieldset>
                              </form>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className='notice'>
                      <h2>{getContent('increasedHeathMeasures').title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getContent('increasedHeathMeasures').content,
                        }}
                      />
                    </div>

                    <div className='notice'>
                      <h2>{getContent('ageRequirements').title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getContent('ageRequirements').content,
                        }}
                      />
                    </div>

                    <div className='notice'>
                      <h2>{getContent('refundCancellationPolicy').title}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getContent('refundCancellationPolicy')
                            .content,
                        }}
                      />
                    </div>
                  </article>
                </div>
              </div>
            )}
          </CoverPage>
        )}
      </ContentContext.Consumer>
    );
  }
}
