import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class CoverPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: this.props.searchTerm,
    };
  }

  _onSearchTermChange = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  render() {
    const {
      breadcrumbs,
      title,
      coverPhoto,
      ctaLink,
      ctaLabel,
      children,
      search,
    } = this.props;
    const { searchTerm } = this.state;

    return (
      <div className='page__content'>
        <div
          className='cover'
          style={{ backgroundImage: `url(${coverPhoto})` }}
        >
          <div className='cover__shade' />
          <div className='cover__content'>
            <h1 className='cover__title'>{title}</h1>
            {ctaLabel && (
              <div className='cover__search'>
                <NavLink className='button button--light' to={ctaLink}>
                  {ctaLabel}
                </NavLink>
              </div>
            )}
            {breadcrumbs && (
              <div className='breadcrumbs'>
                {breadcrumbs.map((item, key) => (
                  <span key={key}>
                    {item.link && (
                      <NavLink to={item.link}>{item.label}</NavLink>
                    )}
                    {!item.link && <span>{item.label}</span>}
                    {key < breadcrumbs.length - 1 && (
                      <>
                        {` `}&rsaquo;{` `}
                      </>
                    )}
                  </span>
                ))}
              </div>
            )}
            {search && (
              <div className='cover__search'>
                <form action='/search/'>
                  <input
                    type='text'
                    className='cover__search__input'
                    name='query'
                    value={searchTerm}
                    onChange={this._onSearchTermChange}
                  />
                  <button
                    type='submit'
                    className='button button--search'
                  ></button>
                </form>
              </div>
            )}
          </div>
        </div>
        <div className='content'>{children}</div>
      </div>
    );
  }
}
