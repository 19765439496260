import React, { Component } from 'react';
import { CoverPage, Loading, Seo } from '../elements';
import API from '../api';

export default class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await API.get('', {
      params: {
        action: 'page',
        slug: 'winelist/',
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {});

    await API.get('', {
      params: {
        action: 'winelist',
      },
    })
      .then((response) => {
        this.setState({
          items: response.data,
        });
      })
      .catch((error) => {});
  };

  onPurchase = (event) => {
    let cart = JSON.parse(localStorage.getItem('cart'));

    let winelist = {
      type: 'winelist',
      id: event.target.dataset.id,
      quantity: 1,
    };

    if (!cart || !cart.winelist) {
      cart = cart && typeof cart === 'object' ? cart : {};
      cart.winelist = [];
    }

    cart.winelist.push(winelist);

    localStorage.setItem('cart', JSON.stringify(cart));

    this.props.history.push('/checkout/');
  };

  render() {
    const { page, items } = this.state;

    if (!page) {
      return <Loading />;
    }

    return (
      <CoverPage title={page.title} coverPhoto={page.largePhoto}>
        <Seo title={page.title} />
        <article
          className='article article--page'
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
        {items && (
          <article className='article article--page'>
            {items.map((item, key) => (
              <div style={{ marginBottom: '30px', textAlign: 'center' }}>
                <h3>
                  {item.name} | ${item.price}
                </h3>
                <div>{item.description}</div>
                <div>
                  <span
                    data-id={item.id}
                    class='button'
                    onClick={this.onPurchase}
                  >
                    Purchase
                  </span>
                </div>
              </div>
            ))}
          </article>
        )}
      </CoverPage>
    );
  }
}
