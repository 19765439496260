import React from 'react';
import API from '../api';
import { ContentContext } from '../context';

export default class ContentProvider extends React.Component {
  getContent = (slug) => {
    if (this.state.content[slug]) {
      return this.state.content[slug];
    }

    return {
      title: '',
      content: '',
    };
  };

  state = {
    content: {},
  };

  componentWillMount() {
    this.loadContent();
  }

  loadContent = async () => {
    await API.get('', {
      params: {
        action: 'content',
      },
    })
      .then((response) => {
        if (response.data) {
          this.setState({
            content: response.data,
          });
        }
      })
      .catch((error) => {});
  };

  render() {
    return (
      <ContentContext.Provider value={{ getContent: this.getContent }}>
        {this.props.children}
      </ContentContext.Provider>
    );
  }
}
