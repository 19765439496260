import React, { Component } from 'react';

export default class FormSubmitBlock extends Component {
  render() {
    let { label, disabled } = this.props;

    return (
      <fieldset className='fieldset'>
        <div className='label__container'></div>
        <div className='input__container'>
          <button
            type='submit'
            className='button'
            disabled={disabled ? true : false}
          >
            {label}
          </button>
        </div>
      </fieldset>
    );
  }
}
