export default function TableTalkPostsGrid({ posts, header }) {
  return (
    <div className='blog-content-layout content'>
      <h1 className='table-talk-header'>{header}</h1>
      <div className='map-blog-section'>
        {posts.map((card, index) => {
          return (
            <a href={`/table-talk/${card.link}`} key={index}>
              <div className='blog-card'>
                <div
                  style={{
                    backgroundImage: `url(https://d2uwrku0rsqwt1.cloudfront.net/large/media/${card.photo})`,
                  }}
                  className='blog-card-img'
                ></div>
                <div className='blog-card-title'>
                  <p>{card.title}</p>
                </div>
                <div className='blog-card-subtitle'>
                  <p>{card.subtitle}</p>
                </div>
                <span className='tag'>{card.tag}</span>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
}
