import React, { Component } from 'react';
import { CoverPage, FormInputBlock, FormSubmitBlock, Seo } from '../elements';
import { Loading } from '../elements';
import API from '../api';

export default class Member extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      amount: 792,
      name: '',
      recipient: '',
      email: '',
      address: '',
      note: '',
      quantity: 1,
      product: null,
      giftCardType: 'Digital Gift Card',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { slug } = this.props.match.params;

    if (slug) {
      await API.get('', {
        params: {
          action: 'classDetails',
          slug: slug,
        },
      })
        .then((response) => {
          this.setState({
            product: response.data,
            amount: response.data.price,
          });
        })
        .catch((error) => {});
    }

    await API.get('', {
      params: {
        action: 'page',
        slug: 'cook-street-membership/',
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  _formOnSubmit = (event) => {
    let cart = JSON.parse(localStorage.getItem('cart'));

    let membership = {
      productId: this.state.product ? this.state.product.id : null,
      amount: this.state.amount,
      name: this.state.firstName + ' ' + this.state.lastName,
      recipient: this.state.email,
      email: this.state.email,
      address: this.state.address,
      note: this.state.note,
    };

    if (!cart || !cart.membership) {
      cart = cart && typeof cart === 'object' ? cart : {};
      cart.membership = [];
    }

    cart.membership.push(membership);

    localStorage.setItem('cart', JSON.stringify(cart));

    this.props.history.push('/checkout/');
  };

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _quantityOnChange = (event) => {
    let quantity = Number(event.target.value);

    this.setState({
      quantity: quantity,
      amount: String(quantity * this.state.product.price),
    });
  };

  render() {
    const { firstName, lastName, email, page } = this.state;

    if (!page) {
      return <Loading />;
    }

    const now = new Date();
    let expiresOn = new Date();
    expiresOn.setMonth(now.getMonth() + 6);

    return (
      <CoverPage title={page.title} coverPhoto={page.largePhoto}>
        <Seo title={page.title} />
        <article
          className='article'
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
        <form
          className='form flex flex-col-reverse sm:flex-row sm:justify-between'
          onSubmit={this._formOnSubmit}
        >
          <div>
            <div className='flex pb-4'>
              <div className='w-[250px]'>Membership Cost</div>
              <div>$792</div>
            </div>

            <FormInputBlock
              label='First Name'
              name='firstName'
              placeholder='Julia'
              required={true}
              value={firstName}
              onChange={this._inputOnChange}
            />
            <FormInputBlock
              label='Last Name'
              name='lastName'
              placeholder='Child'
              required={true}
              value={lastName}
              onChange={this._inputOnChange}
            />

            <FormInputBlock
              label='Email Address'
              name='email'
              type='email'
              value={email}
              required={true}
              placeholder='jane.smith@example.com'
              onChange={this._inputOnChange}
            />

            <FormSubmitBlock label='Continue' />
          </div>
          <div className='md:w-1/3 pb-8 md:pb-0'>
            <div>
              <div className='border rounded border-solid border-gray-600 relative '>
                <div className='bg-[#012b57] inset-0 h-28 absolute'></div>
                <div className='p-4'>
                  <div className='grid gap-4 items-center '>
                    <div className='w-full px-4 z-10 flex justify-center flex-col items-center'>
                      <img
                        alt='CookStreet'
                        src='/assets/images/cookstreet.svg'
                      />
                      <h3 className='text-gray-100 text-xl mt-2 font-normal'>
                        Membership
                      </h3>
                    </div>
                    <div className='text-3xl font-bold mt-4'>
                      {this.state.firstName ? this.state.firstName : 'Julia'}{' '}
                      {this.state.lastName ? this.state.lastName : 'Child'}
                    </div>
                    <div className='text-sm text-gray-500'>
                      Membership Number
                    </div>
                    <div className='text-2xl font-bold'>CSM235F</div>
                  </div>
                  <div className='p-6 bg-gray-100'>
                    <div className='text-center text-sm text-gray-500'>
                      Valid until {expiresOn.toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </CoverPage>
    );
  }
}
