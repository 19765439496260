import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { CoverPage, Seo } from '../elements';
import API from '../api';

export default class Classes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await API.get('', {
      params: {
        action: 'categories',
      },
    })
      .then((response) => {
        this.setState({
          items: response.data,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { items } = this.state;

    return (
      <CoverPage
        title='Classes'
        coverPhoto='/assets/covers/classes.png'
        search={true}
      >
        <Seo
          title='Couples Cooking Class - Date Night Cooking Classes'
          description={
            "Elevate your Date Night with Cook Street's Couples Cooking Class. Discover culinary delights together in our Date Night Cooking Classes. Secure your spot now!"
          }
        />
        <div className='notice mobile_only'>
          Looking for a specific date? Check out our{' '}
          <NavLink to='/calendar/' className='link'>
            Calendar
          </NavLink>
        </div>
        <div className='flex flex-col gap-4 px-[20px] md:px-[50px]'>
          <p>
            To find the perfect cooking class for you on our website, start by
            searching the{' '}
            <a href='/calendar/' alt='Calendar Page' className='link'>
              Calendar page
            </a>{' '}
            to see what’s upcoming and available. Alternatively, you can search
            for a specific class that interests you. If that class is not
            available, you can enter your email information to be notified when
            it is! Once you've found a class that suits your schedule and
            interests, you can book directly through the website for a seamless
            and convenient experience. As always, feel free to contact us at{' '}
            <span className='link'>cafe@cookstreet.com</span>
            if you have any questions or issues.
          </p>
        </div>
        <div className='classlist'>
          {items.map((item, index) => (
            <div key={item.id} className='classlist__item'>
              <NavLink to={`/product-category/${item.slug}/`}>
                <span
                  className='services__item__photo'
                  style={{ backgroundImage: `url(${item.thumbnailPhoto})` }}
                />
                <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
              </NavLink>
            </div>
          ))}
        </div>
      </CoverPage>
    );
  }
}
