import React, { Component } from 'react';

export default class FormTitleBlock extends Component {
  render() {
    let { title } = this.props;

    return (
      <fieldset className='fieldset'>
        <div className='label__container'></div>
        <div className='input__container'>
          <h4>{title}</h4>
        </div>
      </fieldset>
    );
  }
}
