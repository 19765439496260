import React, { useState, useEffect } from 'react';
import API from '../api';
import { Loading } from '../elements';
import TableTalkPostsGrid from '../elements/TableTalkPostsGrid';

export default function TableTalk() {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [newsletter, setNewsletter] = useState({
    first_name: '',
    email: '',
  });
  const [newsletterMessage, setNewsletterMessage] = useState(null);

  const loadData = async () => {
    await API.get('', {
      params: {
        action: 'tableTalkGetAll',
      },
    })
      .then((res) => {
        let items = res.data;
        setPosts(items);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    loadData();
  }, []);

  const onChange = (event) => {
    setNewsletter((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    await API.post(
      'https://hook.us1.make.com/czph107yoakaes7w6mwldpdh4dqc6pyg',
      {
        user: {
          first_name: newsletter.first_name,
          email: newsletter.email,
        },
      }
    )
      .then((res) => {
        console.log('Subscribed to newsletter: ', res);
        setNewsletterMessage('Subscribed to newsletter!');
        setNewsletter({ first_name: '', email: '' });
      })
      .catch((err) => {
        console.log('Error while subscribing to newsletter: ', err);
        setNewsletterMessage('Error subscribing to newsletter');
      });
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className='blog-landing-hero-container'>
            <div className='hero-subscribe'>
              <h1>Interested in a Cook Street Class?</h1>
              <div className='subscribe-copy'>
                <p>
                  We are Denver’s premier experience in interactive cooking and
                  dining events. We have been serving the Denver community for
                  20 years.{' '}
                </p>
                <form className='subscribe-box' onSubmit={(e) => onSubmit(e)}>
                  <div className='subscribe-box-input-container'>
                    <input
                      type='text'
                      placeholder='First Name'
                      name='first_name'
                      onChange={(e) => onChange(e)}
                      value={newsletter.first_name}
                    />
                    <input
                      type='email'
                      placeholder='example@gmail.com'
                      name='email'
                      onChange={(e) => onChange(e)}
                      value={newsletter.email}
                    />
                  </div>
                  <button className='subcribe-btn' type='submit'>
                    <span>Subscribe</span>
                  </button>
                </form>
              </div>
              {newsletterMessage && (
                <p className='newsletter-message'>{newsletterMessage}</p>
              )}
            </div>
          </div>

          <TableTalkPostsGrid posts={posts} header={'Welcome To Table Talk'} />
        </>
      )}
    </>
  );
}
