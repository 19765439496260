import React, { Component } from 'react';

export default class Loading extends Component {
  render() {
    return (
      <p
        style={{ height: '400px', padding: '150px 0 0 0', textAlign: 'center' }}
      >
        Please wait...
      </p>
    );
  }
}
