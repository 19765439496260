import React, { Component } from 'react';

export default class Social extends Component {
  render() {
    return (
      <div className='social'>
        <a
          href='https://www.facebook.com/cookstreetculinary'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img alt='Facebook' src='/assets/icons/facebook.svg' />
        </a>
        <a
          href='https://www.instagram.com/cookstreetschool/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img alt='Instagram' src='/assets/icons/instagram.svg' />
        </a>
        <a
          href='https://www.yelp.com/biz/cook-street-school-of-culinary-arts-denver'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img alt='Yelp' src='/assets/icons/yelp.svg' />
        </a>
      </div>
    );
  }
}
