import React, { Component } from 'react';
import { Link, Element, scrollSpy } from 'react-scroll';
import { CoverPage, Loading, Seo } from '../elements';
import API from '../api';

export default class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.loadData();
    scrollSpy.update();
  }

  loadData = async () => {
    await API.get('', {
      params: {
        action: 'page',
        slug: 'faq/',
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {});

    await API.get('', {
      params: {
        action: 'faq',
      },
    })
      .then((response) => {
        this.setState({
          items: response.data,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { page, items } = this.state;

    if (!page) {
      return <Loading />;
    }

    return (
      <CoverPage title={page.title} coverPhoto={page.largePhoto}>
        <Seo title={page.title} />
        <div className='topics notice'>
          <h2>Topics</h2>
          <ul>
            {items.map((item, key) => (
              <li key={key}>
                <Link
                  offset={-80}
                  className='link'
                  to={`question${item.id}`}
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  {item.question}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {items && (
          <article className='article article--page'>
            {items.map((item, key) => (
              <Element key={key} name={`question${item.id}`}>
                <div>
                  <h2>{item.question}</h2>
                  <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                </div>
              </Element>
            ))}
          </article>
        )}
      </CoverPage>
    );
  }
}
