import React, { Component } from 'react';
import { CoverPage } from '../elements';
import { Loading, Seo } from '../elements';
import API from '../api';

export default class Alumni extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      items: [],
      activeJobId: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let page = this.props.location.pathname.substring(1);

    await API.get('', {
      params: {
        action: 'page',
        slug: page,
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {});

    await API.get('', {
      params: {
        action: 'jobs',
      },
    })
      .then((response) => {
        this.setState({
          items: response.data,
        });
      })
      .catch((error) => {});
  };

  _jobPreviewOnClick = (event) => {
    event.preventDefault();

    this.setState({
      activeJobId: Number(event.target.dataset.id),
    });
  };

  render() {
    const { page, items, activeJobId } = this.state;

    if (!page || !items) {
      return <Loading />;
    }

    return (
      <CoverPage title={page.title} coverPhoto={page.largePhoto}>
        <Seo title='Alumni Services' />
        {items && (
          <div className='jobs'>
            {items.map((item, key) => (
              <div className='job'>
                <div
                  className='job__preview'
                  data-id={item.id}
                  onClick={this._jobPreviewOnClick}
                >
                  <div
                    className='job__logo'
                    data-id={item.id}
                    style={{ backgroundImage: `url(${item.thumbnailPhoto})` }}
                  ></div>
                  <div className='job__title' data-id={item.id}>
                    <h2 data-id={item.id}>{item.title}</h2>
                    <p data-id={item.id}>
                      <strong data-id={item.id}>{item.company}</strong>
                    </p>
                    <p data-id={item.id}>{item.location}</p>
                    {item.tagline && (
                      <p data-id={item.id}>
                        <em>{item.tagline}</em>
                      </p>
                    )}
                  </div>
                  <div className='job__info'>
                    <div className='job__info__type'>{item.jobType}</div>
                  </div>
                </div>
                <div
                  className='job__content'
                  style={{
                    display: item.id === activeJobId ? 'block' : 'none',
                  }}
                >
                  <article
                    className='article'
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <div className='job__apply'>
                    <strong>Apply at</strong>:{' '}
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={`${
                        item.applicationLink
                          ? item.applicationLink
                          : `mailto:${item.applicationEmail}`
                      }`}
                    >
                      {item.applicationLink
                        ? item.applicationLink
                        : item.applicationEmail}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </CoverPage>
    );
  }
}
