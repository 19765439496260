import React, { Component } from 'react';
import { ClassItems, CoverPage, Loading, Seo } from '../elements';
import API from '../api';

export default class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: {},
      items: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { slug } = this.props.match.params;

    await API.get('', {
      params: {
        action: 'category',
        slug: slug,
      },
    })
      .then((response) => {
        this.setState({
          category: response.data,
        });
      })
      .catch((error) => {});

    await API.get('', {
      params: {
        action: 'classesByCategory',
        categorySlug: slug,
      },
    })
      .then((response) => {
        this.setState({
          items: response.data,
        });
      })
      .catch((error) => {});

    localStorage.setItem('category', JSON.stringify(this.state.category));
  };

  render() {
    const { category, items } = this.state;

    if (!items) {
      return <Loading />;
    }

    return (
      <CoverPage
        title={category.title}
        coverPhoto={category.largePhoto}
        search={true}
        breadcrumbs={[
          {
            label: 'Classes',
            link: '/classes',
          },
          {
            label: category.title,
          },
        ]}
      >
        <Seo title={category.title} />
        <ClassItems items={items} />
      </CoverPage>
    );
  }
}
