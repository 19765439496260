import React, { Component } from 'react';
import { CoverPage, FormInputBlock, FormSubmitBlock, Seo } from '../elements';
import API from '../api';
import { ContentContext } from '../context';
import ReCAPTCHA from 'react-google-recaptcha';

export default class Program extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      activeTab: null,
      name: '',
      email: '',
      phone: '',
      isDisabled: true,
    };
  }

  recaptchaRef = React.createRef();

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  loadData = async () => {
    let page = this.props.location.pathname.substring(1);
    let pageData = {};

    this.setState({
      isLoading: true,
    });

    await API.get('', {
      params: {
        action: 'page',
        slug: page,
      },
    })
      .then((response) => {
        pageData = response.data;

        this.setState({
          page: pageData,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    let activeTab = null;

    for (let i in pageData.sections) {
      if (pageData.sections[i].position === 'Tab') {
        activeTab = pageData.sections[i].id;
        break;
      }
    }
    console.log('Page', this.state);
    this.setState({
      isLoading: false,
      activeTab: activeTab,
      isSubmitted: false,
    });
  };

  _tabOnClick = (event) => {
    this.setState({
      activeTab: Number(event.target.dataset.id),
    });
  };

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _onChange = (value) => {
    // console.log('Captcha value:', value);

    if (value === '') {
      this.setState({
        isDisabled: true,
      });
    } else {
      this.setState({
        isDisabled: false,
      });
    }
  };

  _formOnSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    await API.post('', {
      action: this.state.page.inquiryFormContent,
      formItems: [
        {
          title: 'Name',
          value: this.state.name,
        },
        {
          title: 'Email',
          value: this.state.email,
        },
        {
          title: 'Phone',
          value: this.state.phone,
        },
      ],
    })
      .then((response) => {
        this.setState({
          isSubmitting: false,
          isSubmitted: true,
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isSubmitting: false,
        });
      });
  };

  render() {
    const { page, activeTab, isSubmitting, isSubmitted, isDisabled } =
      this.state;

    if (!page) {
      return 'Loading...';
    }

    return (
      <ContentContext.Consumer>
        {({ getContent }) => (
          <CoverPage title={page.title} coverPhoto={page.largePhoto}>
            <Seo title={page.title} description={page.description} />
            <article className='article article--page'>
              {page.subtitle && <h2>{page.subtitle}</h2>}
              {page.inquiryForm === 1 && (
                <div className='inquiry__intro'>
                  <div dangerouslySetInnerHTML={{ __html: page.content }} />
                  <div className='inquiry_form'>
                    <div className='form'>
                      {isSubmitted && (
                        <>
                          <h3>
                            {
                              getContent(`${page.inquiryFormContent}Thankyou`)
                                .title
                            }
                          </h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getContent(
                                `${page.inquiryFormContent}Thankyou`
                              ).content,
                            }}
                          />
                        </>
                      )}
                      {!isSubmitted && (
                        <>
                          <h3>{getContent(page.inquiryFormContent).title}</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: getContent(page.inquiryFormContent)
                                .content,
                            }}
                          />
                          <form onSubmit={this._formOnSubmit}>
                            <FormInputBlock
                              name='name'
                              label='Your Name'
                              value={this.state.name}
                              required={true}
                              onChange={this._inputOnChange}
                            />
                            <FormInputBlock
                              name='email'
                              label='Email Address'
                              value={this.state.email}
                              type='email'
                              required={true}
                              onChange={this._inputOnChange}
                            />
                            <FormInputBlock
                              name='phone'
                              label='Phone'
                              value={this.state.phone}
                              required={true}
                              onChange={this._inputOnChange}
                            />
                            <ReCAPTCHA
                              ref={this.recaptchaRef}
                              sitekey='6LclBb0jAAAAAC_dNc_XMauRJxiMB9TKwCOF0JJ_'
                              onChange={this._onChange}
                            />
                            <FormSubmitBlock
                              disabled={
                                isDisabled ? true : isSubmitted ? true : false
                              }
                              label={isSubmitting ? 'Please wait...' : 'Submit'}
                            />
                          </form>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {!page.inquiryForm && (
                <div dangerouslySetInnerHTML={{ __html: page.content }} />
              )}

              {activeTab && (
                <>
                  <div className='tabs'>
                    {page.sections && (
                      <>
                        {page.sections.map((section, key) => (
                          <>
                            {section.position === 'Tab' && (
                              <div
                                key={key}
                                className={`tab ${
                                  activeTab === section.id ? 'tab--active' : ''
                                }`}
                                data-id={section.id}
                                onClick={this._tabOnClick}
                              >
                                <div
                                  className='tab__photo'
                                  style={{
                                    backgroundImage: `url(${section.thumbnailPhoto})`,
                                  }}
                                  data-id={section.id}
                                />
                                <h3 data-id={section.id}>{section.title}</h3>
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </div>
                  {page.sections.map((section, key) => (
                    <>
                      {section.position === 'Tab' && (
                        <div
                          key={key}
                          className='tab__content'
                          dangerouslySetInnerHTML={{ __html: section.content }}
                          style={{
                            display:
                              activeTab === section.id ? 'block' : 'none',
                          }}
                        />
                      )}
                    </>
                  ))}
                </>
              )}

              {page.sections && (
                <>
                  {page.sections.map((section, key) => (
                    <>
                      {section.position !== 'Tab' && (
                        <div key={key} className='section'>
                          {section.position === 'Left' && (
                            <>
                              <h2>{section.title}</h2>
                              <div className='content__image'>
                                <div
                                  className='content__image__copy'
                                  dangerouslySetInnerHTML={{
                                    __html: section.content,
                                  }}
                                />
                                <div
                                  className='content__image__photo'
                                  style={{
                                    backgroundImage: `url(${section.largePhoto})`,
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {section.position === 'Right' && (
                            <>
                              <h2>{section.title}</h2>
                              <div className='content__image'>
                                <div
                                  className='content__image__photo'
                                  style={{
                                    backgroundImage: `url(${section.largePhoto})`,
                                  }}
                                />
                                <div
                                  className='content__image__copy'
                                  dangerouslySetInnerHTML={{
                                    __html: section.content,
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {section.position !== 'Right' &&
                            section.position !== 'Left' && (
                              <>
                                {section.photo && (
                                  <div
                                    className='article__separator'
                                    style={{
                                      backgroundImage: `url(${section.largePhoto})`,
                                    }}
                                  />
                                )}
                                <h2>{section.title}</h2>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: section.content,
                                  }}
                                />
                              </>
                            )}
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
            </article>
          </CoverPage>
        )}
      </ContentContext.Consumer>
    );
  }
}
