module.exports = {
  config: {
    domain: 'cookstreet.com',
    title: 'Cook Street School of Culinary Arts',
    apiUrl: process.env.REACT_APP_API_URL,
    squareAppId: process.env.REACT_APP_SQUARE_APP_ID,
    squareLocationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
    analyticsId_ga4: 'G-ECF1ZNKMFV',
  },
};
