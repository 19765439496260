import React, { Component } from 'react';

export default class Tour extends Component {
  render() {
    return (
      <div className='wrap' style={{ padding: '100px 0 30px 0' }}>
        <iframe
          title='3D Tour'
          width='100%'
          height='700'
          src='https://my.matterport.com/show/?m=WqVtrUXby85'
          frameborder='0'
          allowfullscreen
          allow='vr'
        ></iframe>
      </div>
    );
  }
}
