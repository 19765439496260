import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

export default class ClassItems extends Component {
  render() {
    const { items } = this.props;

    return (
      <>
        <div className='flex flex-col gap-4 px-[20px] md:px-[50px]'>
          <p>
            Browse our class offerings on this page. If a class lacks an
            <span className='italic font-semibold'> available on date</span>,
            it's not scheduled yet - click{' '}
            <span className='italic font-semibold'>Read More</span> to sign up
            for notification when it becomes available. To enroll, follow the
            <span className='italic font-semibold'> Read More</span> link or
            check our{' '}
            <a href='/calendar/' alt='Calendar Page' className='link'>
              Calendar page
            </a>{' '}
            for all scheduled classes.
          </p>
          <p>
            Can't find a class? Email us at cafe@cookstreet.com to suggest new
            classes based on demand.
          </p>
        </div>
        <div className='categorylist'>
          {items.map((item, index) => (
            <div key={item.id} className='categorylist__item'>
              <div className='categorylist__item__photo'>
                <NavLink to={`/product/${item.slug}/`}>
                  <div
                    className='photo'
                    style={{ backgroundImage: `url(${item.thumbnailPhoto})` }}
                  />
                </NavLink>
              </div>
              <div className='categorylist__item__content'>
                <h2 className='categorylist__item__title'>
                  <NavLink to={`/product/${item.slug}/`}>{item.title}</NavLink>
                </h2>
                {item.nextEvent && (
                  <div className='categorylist__item__mark'>
                    Available on {moment.utc(item.nextEvent).format('MMMM Do')}
                  </div>
                )}
                <div className='categorylist__item__description'>
                  <article
                    className='article'
                    dangerouslySetInnerHTML={{ __html: item.intro }}
                  />
                </div>
                <p>
                  <NavLink to={`/product/${item.slug}/`} className='button'>
                    Read More
                  </NavLink>
                </p>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
