import React, { Component } from 'react';
import {
  CoverPage,
  FormInputBlock,
  FormSelectBlock,
  FormSubmitBlock,
  Seo,
} from '../elements';
import { Loading } from '../elements';
import API from '../api';

export default class GiftCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: null,
      amount: 100,
      name: '',
      recipient: '',
      email: '',
      address: '',
      note: '',
      quantity: 1,
      product: null,
      giftCardType: 'Digital Gift Card',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { slug } = this.props.match.params;

    if (slug) {
      await API.get('', {
        params: {
          action: 'classDetails',
          slug: slug,
        },
      })
        .then((response) => {
          this.setState({
            product: response.data,
            amount: response.data.price,
          });
        })
        .catch((error) => {});
    }

    await API.get('', {
      params: {
        action: 'page',
        slug: 'cook-street-gift-cards/',
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });
  };

  _formOnSubmit = (event) => {
    let cart = JSON.parse(localStorage.getItem('cart'));

    let gift = {
      productId: this.state.product ? this.state.product.id : null,
      amount: this.state.amount,
      name: this.state.name,
      recipient: this.state.recipient,
      email: this.state.email,
      address: this.state.address,
      note: this.state.note,
    };

    if (!cart || !cart.gifts) {
      cart = cart && typeof cart === 'object' ? cart : {};
      cart.gifts = [];
    }

    cart.gifts.push(gift);

    localStorage.setItem('cart', JSON.stringify(cart));

    this.props.history.push('/checkout/');
  };

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _quantityOnChange = (event) => {
    let quantity = Number(event.target.value);

    this.setState({
      quantity: quantity,
      amount: String(quantity * this.state.product.price),
    });
  };

  render() {
    const {
      amount,
      name,
      recipient,
      email,
      address,
      note,
      page,
      product,
      giftCardType,
      quantity,
    } = this.state;

    let giftCardTypes = [
      {
        label: 'Digital Gift Card',
        value: 'Digital Gift Card',
      },
      {
        label: 'Physical Gift Card',
        value: 'Physical Gift Card',
      },
    ];

    if (!page) {
      return <Loading />;
    }

    return (
      <CoverPage title={page.title} coverPhoto={page.largePhoto}>
        <Seo title={page.title} />
        <article
          className='article'
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
        <form className='form' onSubmit={this._formOnSubmit}>
          {product && (
            <div className='payment__item'>
              <div
                className='payment__thumb'
                style={{ backgroundImage: `url(${product.thumbnailPhoto})` }}
              />
              <h3>{product.title}</h3>
              <p>
                <strong>Price per person:</strong>{' '}
                <strong className='payment__price'>${product.price}</strong>
                <strong>Quantity:</strong>{' '}
                <input
                  type='number'
                  className='input input--tiny'
                  value={quantity}
                  min='1'
                  max='100'
                  onChange={this._quantityOnChange}
                />
              </p>
              <p className='info'>
                * This gift card can also be used for other classes.
              </p>
            </div>
          )}
          <FormInputBlock
            label='Gift Card Value'
            name='amount'
            type='number'
            placeholder='100'
            prefix='$'
            additionalClass='input--short'
            value={amount}
            required={true}
            onChange={this._inputOnChange}
          />
          <FormInputBlock
            label='Your Name'
            name='name'
            placeholder='Jack Smith'
            required={true}
            value={name}
            onChange={this._inputOnChange}
          />
          <FormInputBlock
            label='Recipient'
            name='recipient'
            placeholder='Jane Smith'
            required={true}
            value={recipient}
            onChange={this._inputOnChange}
          />
          <FormSelectBlock
            name='giftCardType'
            label='Gift Card Type'
            value={giftCardType}
            options={giftCardTypes}
            defaultValue='Digital Gift Card'
            onChange={this._inputOnChange}
          />
          {this.state.giftCardType === 'Digital Gift Card' && (
            <FormInputBlock
              label="Recipient's Email Address"
              name='email'
              type='email'
              value={email}
              placeholder='jane.smith@example.com'
              labelInfo='For Digital Gift Card'
              onChange={this._inputOnChange}
            />
          )}
          {this.state.giftCardType === 'Physical Gift Card' && (
            <FormInputBlock
              label="Recipient's Mailing Address"
              name='address'
              type='area'
              value={address}
              labelInfo='For Physical Gift Card'
              placeholder='Ex. Jane Smith 1937 Market St Denver CO, 80202'
              onChange={this._inputOnChange}
            />
          )}

          <FormInputBlock
            label='Personal Note'
            name='note'
            placeholder='Ex. Jane, Enjoy a class on us! Love, Jack & Diane'
            value={note}
            type='area'
            onChange={this._inputOnChange}
          />
          <FormSubmitBlock label='Continue' />
        </form>
      </CoverPage>
    );
  }
}
