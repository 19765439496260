import React, { Component } from 'react';
import {
  CoverPage,
  FormInputBlock,
  FormSelectBlock,
  FormSubmitBlock,
  FormTitleBlock,
  Social,
  Seo,
} from '../elements';
import { Loading } from '../elements';
import API from '../api';
import { ContentContext } from '../context';
import countryData from '../data/countries.json';

export default class Home extends Component {
  constructor(props) {
    super(props);

    let countries = [];

    for (let i in countryData) {
      countries.push({
        label: countryData[i].name,
        value: countryData[i].name,
      });
    }

    this.state = {
      isSubmitted: false,
      isSubmitting: false,
      countries: countries,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      zip: '',
      state: '',
      country: 'United States',
      interest: 'Classes for the Home Cook',
      content: '',
      interests: [
        {
          label: 'Classes for the Home Cook',
          value: 'Classes for the Home Cook',
        },
        {
          label: 'Private Events',
          value: 'Private Events',
        },
        {
          label: 'Culinary Arts Program',
          value: 'Culinary Arts Program',
        },
        {
          label: 'Sign Up for the Newsletter',
          value: 'Sign Up for the Newsletter',
        },
        {
          label: 'Schedule a Tour',
          value: 'Schedule a Tour',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  loadData = async () => {
    let page = this.props.location.pathname.substring(1);

    this.setState({
      isLoading: true,
    });

    await API.get('', {
      params: {
        action: 'page',
        slug: page,
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    this.setState({
      isLoading: false,
    });
  };

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _formOnSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    await API.post('', {
      action: 'contactSend',
      formItems: [
        {
          title: 'First Name',
          value: this.state.firstName,
        },
        {
          title: 'Last Name',
          value: this.state.lastName,
        },
        {
          title: 'Email',
          value: this.state.email,
        },
        {
          title: 'Phone',
          value: this.state.phone,
        },
        {
          title: 'Address',
          value: this.state.addressLine1,
        },
        {
          title: 'Address (line 2)',
          value: this.state.addressLine2,
        },
        {
          title: 'City',
          value: this.state.city,
        },
        {
          title: 'Zip',
          value: this.state.zip,
        },
        {
          title: 'State',
          value: this.state.state,
        },
        {
          title: 'Country',
          value: this.state.country,
        },
        {
          title: 'Interest',
          value: this.state.interest,
        },
        {
          title: 'Content',
          value: this.state.content,
        },
      ],
    })
      .then((response) => {
        this.setState({
          isSubmitting: false,
          isSubmitted: true,
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isSubmitting: false,
        });
      });
  };

  render() {
    const { isSubmitted, isSubmitting, page, interests, countries } =
      this.state;

    if (!page) {
      return <Loading />;
    }

    return (
      <ContentContext.Consumer>
        {({ getContent }) => (
          <CoverPage title={page.title} coverPhoto={page.largePhoto}>
            <Seo title={page.title} />
            <article className='article article--page'>
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
            </article>
            <div className='contact__map'>
              <iframe
                title='Maps'
                src='https://www.google.com/maps/embed/v1/place?key=AIzaSyD09zQ9PNDNNy9TadMuzRV_UsPUoWKntt8&amp;q=43W+9th+Ave+Denver%2C+CO+80204'
                style={{ border: 0, width: '100%', height: '300px' }}
              ></iframe>
            </div>
            <div className='contact__info'>
              <div className='contact__info__item'>
                <h3>{getContent('contactWorkTime').title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getContent('contactWorkTime').content,
                  }}
                />
              </div>
              <div className='contact__info__item'>
                <h3>{getContent('contactHeadquarters').title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getContent('contactHeadquarters').content,
                  }}
                />
              </div>
              <div className='contact__info__item'>
                <h3>{getContent('contactProgram').title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getContent('contactProgram').content,
                  }}
                />
              </div>
              <div className='contact__info__item'>
                <h3>{getContent('contactPhone').title}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getContent('contactPhone').content,
                  }}
                />
              </div>
            </div>
            <Social />
            <div className='contact__form'>
              {isSubmitted && (
                <article className='article form'>
                  <h2>{getContent('contactThankyou').title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getContent('contactThankyou').content,
                    }}
                  />
                </article>
              )}

              {!isSubmitted && (
                <form className='form' onSubmit={this._formOnSubmit}>
                  <FormInputBlock
                    name='firstName'
                    label='First Name'
                    value={this.state.firstName}
                    required={true}
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='lastName'
                    label='Last Name'
                    value={this.state.lastName}
                    required={true}
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='email'
                    label='Email'
                    type='email'
                    value={this.state.email}
                    required={true}
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='phone'
                    label='Phone'
                    value={this.state.phone}
                    onChange={this._inputOnChange}
                  />
                  <FormTitleBlock title='Address' />
                  <FormInputBlock
                    name='addressLine1'
                    label='Street Address'
                    value={this.state.addressLine1}
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='addressLine2'
                    label='Street Address Line 2'
                    value={this.state.addressLine2}
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='city'
                    label='City'
                    value={this.state.city}
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='zip'
                    label='Postal / Zip Code'
                    value={this.state.zip}
                    additionalClass='input--short'
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='state'
                    label='State'
                    value={this.state.state}
                    onChange={this._inputOnChange}
                  />
                  <FormSelectBlock
                    name='country'
                    label='Country'
                    value={this.state.country}
                    options={countries}
                    defaultValue='United States'
                    onChange={this._inputOnChange}
                  />
                  <FormTitleBlock title='Message' />
                  <FormSelectBlock
                    name='interest'
                    label='Interest'
                    value={this.state.interest}
                    required={true}
                    options={interests}
                    onChange={this._inputOnChange}
                  />
                  <FormInputBlock
                    name='content'
                    label='How can we help you?'
                    value={this.state.content}
                    type='area'
                    required={true}
                    onChange={this._inputOnChange}
                  />
                  <FormSubmitBlock
                    disabled={isSubmitting}
                    label={isSubmitting ? 'Please wait...' : 'Submit'}
                  />
                </form>
              )}
            </div>
          </CoverPage>
        )}
      </ContentContext.Consumer>
    );
  }
}
