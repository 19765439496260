import React, { Component } from 'react';
import { ClassItems, CoverPage, Loading, Seo } from '../elements';
import API from '../api';

import queryString from 'query-string';

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: {},
      items: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    let query = queryString.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).query;

    if (!query) {
      this.props.history.push('/classes/');
      return;
    }

    this.setState({
      query: query,
    });

    await API.get('', {
      params: {
        action: 'classesSearch',
        query: query,
      },
    })
      .then((response) => {
        this.setState({
          items: response.data,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { items, query } = this.state;

    if (!query) {
      return <Loading />;
    }

    if (!items) {
      return <Loading />;
    }

    return (
      <CoverPage
        title='Classes'
        coverPhoto='/assets/covers/classes.png'
        search={true}
        searchTerm={query}
        breadcrumbs={[
          {
            label: 'Classes',
            link: '/classes/',
          },
          {
            label: 'Search Results',
          },
        ]}
      >
        <Seo title='Classes' />
        <ClassItems items={items} />
      </CoverPage>
    );
  }
}
