import React, { Component } from 'react';
import {
  CoverPage,
  FormInputBlock,
  FormSelectBlock,
  FormSubmitBlock,
  FormTitleBlock,
} from '../elements';
import API from '../api';

export default class JobPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false,
      isSubmitting: false,
      types: [],
      page: {
        title: 'Post a Job',
        largePhoto: '/assets/covers/01.jpg',
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  loadData = async () => {
    let page = this.props.location.pathname.substring(1);

    this.setState({
      isLoading: true,
    });

    await API.get('', {
      params: {
        action: 'jobTypes',
      },
    })
      .then((response) => {
        let types = [];

        for (let i in response.data) {
          types.push({
            value: response.data[i].id,
            label: response.data[i].title,
          });
        }

        this.setState({
          types: types,
        });
      })
      .catch((error) => {});

    await API.get('', {
      params: {
        action: 'page',
        slug: page,
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    this.setState({
      isLoading: false,
    });
  };

  _inputOnChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  _formOnSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    await API.post('', {
      action: 'jobPost',
      logo: this.state.logo,
      email: this.state.email,
      title: this.state.title,
      location: this.state.location,
      jobTypeId: this.state.jobTypeId,
      applicationEmail: this.state.applicationEmail,
      applicationLink: this.state.applicationLink,
      description: this.state.description,
      company: this.state.company,
      website: this.state.website,
      tagline: this.state.tagline,
      twitter: this.state.twitter,
      zip: this.state.zip,
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
      });

    this.setState({
      isSubmitting: false,
      isSubmitted: true,
    });
  };

  render() {
    const { isSubmitted, page, types } = this.state;

    if (!page) {
      return 'Loading...';
    }

    return (
      <CoverPage title={page.title} coverPhoto={page.largePhoto}>
        <article className='article article--page'>
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </article>
        <div className='contact__form'>
          {isSubmitted && (
            <article className='article form'>
              Thank you for your submission!
            </article>
          )}
          {!isSubmitted && (
            <form className='form' onSubmit={this._formOnSubmit}>
              <FormInputBlock
                name='logo'
                label='Your Logo'
                type='file'
                required={false}
                value={this.state.logo}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='email'
                label='Your Email'
                type='email'
                required={true}
                value={this.state.email}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='title'
                label='Job Title'
                required={true}
                value={this.state.title}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='location'
                label='Location'
                placeholder='Ex. Denver'
                value={this.state.location}
                onChange={this._inputOnChange}
              />
              <FormSelectBlock
                name='jobTypeId'
                label='Job Type'
                required={true}
                options={types}
                value={this.state.jobTypeId}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='applicationEmail'
                label='Application Email'
                value={this.state.applicationEmail}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='applicationLink'
                label='Application Link'
                placeholder='https://'
                value={this.state.applicationLink}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='description'
                label='Description'
                type='area'
                required={true}
                value={this.state.description}
                onChange={this._inputOnChange}
              />
              <FormTitleBlock title='Company Details' />
              <FormInputBlock
                name='company'
                label='Company Name'
                required={true}
                value={this.state.company}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='website'
                label='Website'
                placeholder='https://'
                value={this.state.website}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='tagline'
                label='Tagline'
                placeholder='Briefly describe your company'
                value={this.state.tagline}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='twitter'
                label='Twitter username'
                placeholder='@yourcompany'
                value={this.state.twitter}
                onChange={this._inputOnChange}
              />
              <FormInputBlock
                name='zip'
                label='Postal / Zip Code'
                additionalClass='input--short'
                value={this.state.zip}
                onChange={this._inputOnChange}
              />
              <FormSubmitBlock label='Submit' />
            </form>
          )}
        </div>
      </CoverPage>
    );
  }
}
