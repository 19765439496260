import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMenuOpen: false,
    };
  }

  onMobileMenuClick = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };

  hideMobileMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  render() {
    let headerClassNames = ['header'];

    if (this.state.mobileMenuOpen) {
      headerClassNames.push('header--open');
    }

    return (
      <header className={headerClassNames.join(' ')}>
        <div className='wrap'>
          <div className='desktop-menu'>
            <div className='header__menu header__menu--first'>
              <NavLink
                onClick={this.hideMobileMenu}
                to='/'
                className='header__link'
                activeClassName='header__link--active'
                exact
              >
                Home
              </NavLink>
              <div className='header__menu__section'>
                <NavLink
                  onClick={this.hideMobileMenu}
                  to='/calendar/'
                  className='header__link header__link--mobile'
                  activeClassName='header__link--active'
                >
                  Calendar
                </NavLink>
                <NavLink
                  onClick={this.hideMobileMenu}
                  to='/classes/'
                  className='header__link header__link--mobile'
                  activeClassName='header__link--active'
                >
                  Classes
                </NavLink>
              </div>
              <div className='header__menu__section'>
                <NavLink
                  onClick={this.hideMobileMenu}
                  to='/culinary-arts-programs/'
                  className='header__link'
                  activeClassName='header__link--active'
                >
                  Professional Programs
                </NavLink>
                <div className='header__submenu'>
                  <div className='header__submenu__section'>Culinary Arts</div>
                  <NavLink
                    onClick={this.hideMobileMenu}
                    to='/culinary-arts-programs/'
                    className='header__link--inner'
                    activeClassName='header__link--active'
                  >
                    Culinary Arts Programs
                  </NavLink>
                  <NavLink
                    to='/culinary-arts-programs/admissions/'
                    className='header__link--inner'
                    activeClassName='active'
                  >
                    Admissions
                  </NavLink>
                  <NavLink
                    to='/culinary-arts-programs/complete-an-application/'
                    className='header__link--inner'
                    activeClassName='active'
                  >
                    Complete an Application
                  </NavLink>
                  <hr />

                  <div className='header__submenu__section'>
                    Hospitality Management
                  </div>
                  <NavLink
                    onClick={this.hideMobileMenu}
                    to='/hospitality-management-program/'
                    className='header__link--inner'
                    activeClassName='header__link--active'
                  >
                    Hospitality Management
                  </NavLink>
                  <NavLink
                    to='/hospitality-management-program/admissions/'
                    className='header__link--inner'
                    activeClassName='active'
                  >
                    Admissions
                  </NavLink>
                  <NavLink
                    to='/hospitality-management-program/complete-an-application/'
                    className='header__link--inner'
                    activeClassName='active'
                  >
                    Complete an Application
                  </NavLink>
                  <hr />

                  <NavLink to='/alumni/' activeClassName='active'>
                    Alumni Services
                  </NavLink>
                  <NavLink to='/alumni/post-a-job' activeClassName='active'>
                    Post a Job
                  </NavLink>
                </div>
              </div>
              <div className='header__menu__section'>
                <NavLink
                  onClick={this.hideMobileMenu}
                  to='/about-us/'
                  className='header__link'
                  activeClassName='header__link--active'
                >
                  About Us
                </NavLink>
                <div className='header__submenu'>
                  <NavLink to='/contact-us/' activeClassName='active'>
                    Contact Us
                  </NavLink>
                  <NavLink to='/3dtour/' activeClassName='active'>
                    3D Tour
                  </NavLink>
                  <NavLink to='/about-us/staff/' activeClassName='active'>
                    Staff
                  </NavLink>
                  <NavLink to='/table-talk/' activeClassName='active'>
                    Table Talk
                  </NavLink>
                  <NavLink
                    style={{ display: 'none' }}
                    to='/partners/'
                    activeClassName='active'
                  >
                    Our Partners
                  </NavLink>
                </div>
              </div>
              <NavLink
                onClick={this.hideMobileMenu}
                to='/faq/'
                className='header__link'
                activeClassName='header__link--active'
              >
                FAQ
              </NavLink>
            </div>
            <div className='header__logo'>
              <NavLink onClick={this.hideMobileMenu} exact to='/'>
                <img alt='CookStreet' src='/assets/images/cookstreet.svg' />
              </NavLink>
            </div>
            <div className='header__menu header__menu--second'>
              <NavLink
                onClick={this.hideMobileMenu}
                to='/private-events/'
                className='header__link'
                activeClassName='header__link--active'
              >
                Private Events
              </NavLink>
              <NavLink
                onClick={this.hideMobileMenu}
                to='/classes/'
                className='header__link header__link--desktop'
                activeClassName='header__link--active'
              >
                Classes
              </NavLink>
              <NavLink
                onClick={this.hideMobileMenu}
                to='/calendar/'
                className='header__link header__link--desktop'
                activeClassName='header__link--active'
              >
                Calendar
              </NavLink>
              <div className='header__menu__section'>
                <NavLink
                  onClick={this.hideMobileMenu}
                  to='/cook-street-gift-cards/'
                  className='header__link'
                  activeClassName='header__link--active'
                >
                  Gift Cards
                </NavLink>
                <div className='header__submenu'>
                  <NavLink
                    onClick={this.hideMobileMenu}
                    to='/cook-street-membership/'
                    activeClassName='active'
                  >
                    Membership
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className='mobile-menu'>
            <img
              onClick={this.onMobileMenuClick}
              className='mobile-menu__trigger'
              alt=''
              src='/assets/icons/menu.svg'
            />
          </div>
        </div>
      </header>
    );
  }
}
