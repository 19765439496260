import React, { Component } from 'react';

export default class FormSelectBlock extends Component {
  render() {
    let {
      label,
      labelInfo,
      name,
      required,
      options,
      value,
      defaultValue,
      onChange,
    } = this.props;

    if (!value) {
      value = defaultValue;
    }

    return (
      <fieldset className='fieldset'>
        <div className='label__container'>
          <label className='label'>
            {label}
            {required && <span className='input__required'>*</span>}
          </label>
          {labelInfo && <p className='label__info'>{labelInfo}</p>}
        </div>
        <div className='input__container'>
          {options && (
            <select
              className='input'
              name={name}
              required={required}
              value={value}
              onChange={onChange}
            >
              {options.map((item, key) => (
                <option key={key} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          )}
        </div>
      </fieldset>
    );
  }
}
