import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import {
  Loading,
  FormInputBlock,
  FormSelectBlock,
  FormSubmitBlock,
  FormTitleBlock,
} from '../elements';
import moment from 'moment';
import API from '../api';
import { config } from '../config';
import countryData from '../data/countries.json';

export default class Checkout extends Component {
  constructor(props) {
    super(props);

    let countries = [];

    for (let i in countryData) {
      countries.push({
        label: countryData[i].name,
        value: countryData[i].name,
      });
    }

    this.state = {
      purchased: false,
      isLoading: false,
      cartData: JSON.parse(localStorage.getItem('cart')),
      data: null,
      countries: countries,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      notes: '',
      addressLine1: '',
      addressLine2: '',
      state: '',
      zip: '',
      city: '',
      note: '',
      country: 'United States',
      couponCode: '',
      membershipCode: '',
      newsletter: true,
      couponError: null,
      payButtonLoadingState: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
    });

    let checkData = this.getCheckedData();

    if (!checkData.events) {
      this.setState({
        data: null,
      });
    }

    await API.post('', {
      action: 'cartCheck',
      events: JSON.stringify(checkData.events),
      gifts: JSON.stringify(checkData.gifts),
      membership: JSON.stringify(checkData.membership),
      winelist: JSON.stringify(checkData.winelist),
      couponCode: this.state.couponCode,
      membershipCode: this.state.membershipCode,
      giftCardCode: this.state.giftCardCode,
    })
      .then((response) => {
        let data = response.data;

        // console.log('cartCheck Response', data);
        for (let i in data.items) {
          switch (data.items[i].type) {
            case 'class':
              data.items[i].photo = data.items[i].class.thumbnailPhoto;
              break;
            case 'gift-card':
              data.items[i].photo = null;
              break;
            case 'membership':
              data.items[i].photo = null;
              break;
            default:
              data.items[i].photo = null;
              break;
          }
        }

        let couponError =
          this.state.couponCode && !data.couponId && !data.giftCardId;

        if (data.errors?.giftCardError) {
          this.setState({
            couponCode: '',
          });
          alert(`${data.errors?.giftCardError.message}`);
        }
        if (data.errors?.membershipError) {
          this.setState({
            membershipCode: '',
          });
          alert(`${data.errors?.membershipError.message}`);
        }

        if (checkData.events) {
          let newEvents = [];

          for (let i in checkData.events) {
            let eventFound = false;

            for (let j in data.items) {
              if (checkData.events[i].id === data.items[j].id) {
                eventFound = true;
              }
            }

            if (eventFound) {
              newEvents.push(checkData.events[i]);
            }
          }

          checkData.events = newEvents.length ? newEvents : null;
          localStorage.setItem('cart', JSON.stringify(checkData));
        }

        if (checkData.winelist) {
          let newWinelist = [];

          for (let i in checkData.winelist) {
            let winelistFound = false;

            for (let j in data.items) {
              if (
                Number(checkData.winelist[i].id) === Number(data.items[j].id)
              ) {
                winelistFound = true;
              }
            }

            if (winelistFound) {
              newWinelist.push(checkData.winelist[i]);
            }
          }

          checkData.winelist = newWinelist.length ? newWinelist : null;
          localStorage.setItem('cart', JSON.stringify(checkData));
        }

        if (data.membershipCode) {
          if (data.member) {
            checkData.member = data.member;
            localStorage.setItem('cart', JSON.stringify(checkData));
          }
        }

        this.setState(
          {
            cartData: checkData,
            data: typeof data === 'object' ? data : null,
            couponError: couponError,
            firstName: data.member?.firstName,
            lastName: data.member?.lastName,
            email: data.member?.email,
            phone: data.member?.phone,
            addressLine1: data.member?.addressLine1,
            addressLine2: data.member?.addressLine2,
            state: data.member?.state,
            zip: data.member?.zip,
            city: data.member?.city,
            country: data.member?.country || 'United States',
          },
          this.checkState
        );
      })
      .catch((error) => {});

    this.setState({
      isLoading: false,
    });
  };

  getCheckedData() {
    let cartData = this.state.cartData;
    let checkData = {};

    if (cartData.events && cartData.events.length > 0) {
      checkData.events = [];

      for (let i in cartData.events) {
        checkData.events.push({
          id: cartData.events[i].id,
          quantity: cartData.events[i].quantity
            ? cartData.events[i].quantity
            : 1,
          onlineBooking: cartData.events[i].onlineBooking ? true : false,
        });
      }
    }

    if (cartData.gifts && cartData.gifts.length > 0) {
      checkData.gifts = [];

      for (let i in cartData.gifts) {
        checkData.gifts.push(cartData.gifts[i]);
      }
    }

    if (cartData.membership && cartData.membership.length > 0) {
      checkData.membership = [];

      for (let i in cartData.membership) {
        checkData.membership.push(cartData.membership[i]);
      }
    }

    if (cartData.winelist && cartData.winelist.length > 0) {
      checkData.winelist = [];

      for (let i in cartData.winelist) {
        checkData.winelist.push(cartData.winelist[i]);
      }
    }
    // console.log('checkData', checkData);
    return checkData;
  }

  checkState() {
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'phone',
      'addressLine1',
      'state',
      'zip',
      'city',
      'country',
    ];
    const filled = requiredFields.every((field) => !!this.state[field]);

    this.setState({ payButtonLoadingState: !filled });
  }

  _inputOnChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.checkState
    );
  };

  _newsletterOnSubscribe = (event) => {
    this.setState({
      newsletter: event.target.checked,
    });
  };

  _quantityOnIncrease = (event) => {
    let eventId = Number(event.target.dataset.id);
    let cartData = this.state.cartData;

    for (let i in cartData.events) {
      if (eventId === Number(cartData.events[i].id)) {
        cartData.events[i].quantity++;
        break;
      }
    }

    this.updateCart(cartData);
  };

  _quantityOnDecrease = (event) => {
    let eventId = Number(event.target.dataset.id);
    let cartData = this.state.cartData;

    for (let i in cartData.events) {
      if (eventId === Number(cartData.events[i].id)) {
        if (cartData.events[i].quantity > 1) {
          cartData.events[i].quantity--;
        }
        break;
      }
    }

    this.updateCart(cartData);
  };

  _quantityOnChange = (event) => {
    let eventId = Number(event.target.dataset.id);
    let value = Number(event.target.value);
    let cartData = this.state.cartData;

    for (let i in cartData.events) {
      if (eventId === Number(cartData.events[i].id)) {
        cartData.events[i].quantity = value;
        break;
      }
    }

    this.updateCart(cartData);
  };

  _winelistQuantityOnIncrease = (event) => {
    let winelistId = Number(event.target.dataset.id);
    let cartData = this.state.cartData;

    for (let i in cartData.winelist) {
      if (winelistId === Number(cartData.winelist[i].id)) {
        cartData.winelist[i].quantity++;
        break;
      }
    }

    this.updateCart(cartData);
  };

  _winelistQuantityOnDecrease = (event) => {
    let winelistId = Number(event.target.dataset.id);
    let cartData = this.state.cartData;

    for (let i in cartData.winelist) {
      if (winelistId === Number(cartData.winelist[i].id)) {
        if (cartData.winelist[i].quantity > 1) {
          cartData.winelist[i].quantity--;
        }
        break;
      }
    }

    this.updateCart(cartData);
  };

  _winelistQuantityOnChange = (event) => {
    let winelistId = Number(event.target.dataset.id);
    let value = Number(event.target.value);
    let cartData = this.state.cartData;

    for (let i in cartData.winelist) {
      if (winelistId === Number(cartData.winelist[i].id)) {
        cartData.winelist[i].quantity = value;
        break;
      }
    }

    this.updateCart(cartData);
  };

  _applyOnClick = (event) => {
    event.preventDefault();

    this.loadData();
  };

  _formOnSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    await this.submitPayment('gift-card');

    this.setState({
      isSubmitting: false,
    });
  };

  _removeItemOnClick = (event) => {
    let id = Number(event.target.dataset.id);
    let type = event.target.dataset.type;
    let cartData = this.state.cartData;
    let eventCount = cartData.events ? cartData.events.length : 0;

    if (type === 'class') {
      for (let i in cartData.events) {
        if (id === Number(cartData.events[i].id)) {
          cartData.events.splice(i, 1);
          break;
        }
      }
    }

    if (type === 'gift-card') {
      for (let i in cartData.gifts) {
        if (id === eventCount + Number(i)) {
          cartData.gifts.splice(i, 1);
          break;
        }
      }
    }
    if (type === 'membership') {
      for (let i in cartData.membership) {
        if (id === eventCount + Number(i)) {
          cartData.membership.splice(i, 1);
          break;
        }
      }
    }

    if (type === 'winelist') {
      for (let i in cartData.winelist) {
        if (id === Number(cartData.winelist[i].id)) {
          cartData.winelist.splice(i, 1);
          break;
        }
      }
    }

    this.updateCart(cartData);
  };

  _openDialogOnClick = () => {
    document.getElementById('membership-code-dialog').setAttribute('open', '');
  };

  _closeDialogOnClick = () => {
    document.getElementById('membership-code-dialog').removeAttribute('open');
  };

  updateCart = (cartData) => {
    // console.log('UPDATE CART');
    // console.log(cartData);
    this.setState({
      cartData: cartData,
    });

    localStorage.setItem('cart', JSON.stringify(cartData));

    this.loadData();
  };

  handlePaymentMethod = async (token, buyer) => {
    this.setState({ payButtonLoadingState: true });

    this.submitPayment(token);
  };

  submitPayment = async (token) => {
    let checkData = this.getCheckedData();

    if (this.state.newsletter) {
      await API.post(
        'https://hook.us1.make.com/czph107yoakaes7w6mwldpdh4dqc6pyg',
        {
          user: {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
          },
        }
      )
        .then((res) => {
          console.log('Subscribed to newsletter:');
        })
        .catch((err) => {
          console.log('Error while subscribing to newsletter: ', err);
        });
    }

    await API.post('', {
      action: 'payment',
      sourceId: token.token,
      events: JSON.stringify(checkData.events),
      gifts: JSON.stringify(checkData.gifts),
      membership: JSON.stringify(checkData.membership),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      note: this.state.note,
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      state: this.state.state,
      zip: this.state.zip,
      city: this.state.city,
      country: this.state.country,
      newsletter: this.state.newsletter,
      couponCode: this.state.couponCode,
      membershipCode: this.state.membershipCode,
    })
      .then((response) => {
        let data = response.data;

        for (let i in data.items) {
          switch (data.items[i].type) {
            case 'class':
              data.items[i].photo = data.items[i].class.thumbnailPhoto;
              break;
            case 'gift-card':
              data.items[i].photo = null;
              break;
            default:
              data.items[i].photo = null;
              break;
          }
        }

        // console.log('api post response', data);

        this.setState({
          purchased: true,
        });

        this.setState({ payButtonLoadingState: false });

        localStorage.removeItem('cart');
      })
      .catch((error) => {
        alert('Please check your credit card details and try again.');
        this.setState({
          error: true,
        });
        this.setState({ payButtonLoadingState: false });
      });
  };

  onCreate = (instance) => {
    console.log('onCreate');
  };

  onDestroyStart = () => {
    console.log('onDestroyStart');
  };

  onDestroyEnd = () => {
    console.log('onDestroyEnd');
  };

  onError = (error) => {
    console.log('onError', error);
  };

  onNonceError = (error) => {
    alert('Invalid Credit Card information. Please try again.');
  };

  onGoBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      purchased,
      isLoading,
      data,
      countries,
      firstName,
      lastName,
      email,
      phone,
      note,
      addressLine1,
      addressLine2,
      state,
      zip,
      city,
      country,
      membershipCode,
      couponCode,
      newsletter,
      isSubmitting,
    } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    // console.log('state', this.state);

    return (
      <div className='page__content'>
        <div className='content'>
          <div className='payment__info'>
            <article className='article'>
              <h1>Checkout</h1>
              <div className='back'>
                <a onClick={this.onGoBack}>&laquo; Go Back</a>
              </div>
              {data && data.items && (
                <>
                  {data.items.map((item, key) => (
                    <div key={key} className='payment__item'>
                      {item.type === 'class' && (
                        <>
                          <NavLink
                            to={`/product/${item.class.slug}/`}
                            className='payment__thumb'
                            style={{ backgroundImage: `url(${item.photo})` }}
                          />
                          <h3>
                            <NavLink to={`/product/${item.class.slug}/`}>
                              {item.title}
                            </NavLink>
                          </h3>
                          <p>
                            {moment
                              .utc(item.startsAt)
                              .format('MMMM Do @ h:mma')}{' '}
                            - {moment.utc(item.endsAt).format('h:mma')}
                          </p>
                          <p className='payment--option'>
                            <strong>Price:</strong>{' '}
                            <strong className='payment__price'>
                              ${item.totalPrice}
                            </strong>
                            <strong>Quantity:</strong>{' '}
                            <input
                              type='text'
                              className='input input--tiny input--quantity'
                              value={item.quantity}
                              data-id={item.id}
                              onChange={this._quantityOnChange}
                            />{' '}
                            <button
                              data-id={item.id}
                              onClick={this._quantityOnIncrease}
                              className='button button--light button--tiny'
                            >
                              +
                            </button>{' '}
                            <button
                              data-id={item.id}
                              onClick={this._quantityOnDecrease}
                              className='button button--light button--tiny'
                            >
                              -
                            </button>
                            <button
                              className='payment__remove'
                              data-type='class'
                              data-id={item.id}
                              onClick={this._removeItemOnClick}
                            >
                              Remove from Cart
                            </button>
                          </p>
                        </>
                      )}
                      {item.type === 'gift-card' && (
                        <>
                          <div
                            className='payment__thumb'
                            style={{
                              backgroundImage: `url(/assets/images/gift-card.jpg)`,
                            }}
                          />
                          <h3>Cook Street Gift Card</h3>
                          <p>
                            <strong>Amount:</strong>{' '}
                            <strong className='payment__price'>
                              ${item.price}
                            </strong>
                            <button
                              className='payment__remove'
                              data-type='gift-card'
                              data-id={key}
                              onClick={this._removeItemOnClick}
                            >
                              Remove from Cart
                            </button>
                          </p>
                          <p>
                            Recipient: <strong>{item.recipient}</strong>
                          </p>
                        </>
                      )}
                      {item.type === 'membership' && (
                        <>
                          <div
                            className='payment__thumb'
                            style={{
                              backgroundImage: `url(/assets/images/gift-card.jpg)`,
                            }}
                          />
                          <h3>Cook Street Membership</h3>
                          <p>
                            <strong>Amount:</strong>{' '}
                            <strong className='payment__price'>
                              ${item.price}
                            </strong>
                            <button
                              className='payment__remove'
                              data-type='membership'
                              data-id={key}
                              onClick={this._removeItemOnClick}
                            >
                              Remove from Cart
                            </button>
                          </p>
                          <p>
                            Recipient: <strong>{item.recipient}</strong>
                          </p>
                        </>
                      )}
                      {item.type === 'winelist' && (
                        <>
                          <h3>Wine: {item.name}</h3>
                          <p>
                            <strong>Amount:</strong>{' '}
                            <strong className='payment__price'>
                              ${item.price}
                            </strong>
                            <strong>Quantity:</strong>{' '}
                            <input
                              type='text'
                              className='input input--tiny input--quantity'
                              value={item.quantity}
                              data-id={item.id}
                              onChange={this._winelistQuantityOnChange}
                            />{' '}
                            <button
                              data-id={item.id}
                              onClick={this._winelistQuantityOnIncrease}
                              className='button button--light button--tiny'
                            >
                              +
                            </button>{' '}
                            <button
                              data-id={item.id}
                              onClick={this._winelistQuantityOnDecrease}
                              className='button button--light button--tiny'
                            >
                              -
                            </button>
                            <button
                              className='payment__remove'
                              data-type='winelist'
                              data-id={item.id}
                              onClick={this._removeItemOnClick}
                            >
                              Remove from Cart
                            </button>
                          </p>
                        </>
                      )}
                    </div>
                  ))}
                  <div className='payment__total'>
                    <strong>Total: </strong>
                    {data.totalPriceDiscounted === data.totalPrice && (
                      <strong className='payment__price'>
                        ${data.totalPrice}
                      </strong>
                    )}
                    {data.totalPriceDiscounted !== data.totalPrice && (
                      <strong className='payment__price'>
                        <s>${data.totalPrice}</s> ${data.totalPriceDiscounted}
                      </strong>
                    )}
                  </div>
                </>
              )}
              {!data && <div className='notice'>Your cart is empty.</div>}
            </article>
            {purchased && (
              <article className='article'>
                <h1>Thank you for your purchase!</h1>
                <p>You will receive an email confirmation shortly.</p>
              </article>
            )}
          </div>
          {data && !purchased && (
            <>
              <div className='checkout__coupons'>
                <form>
                  <div className=''>
                    <div className='checkout__coupons__options'>
                      <p>Do you have a gift card or a coupon?</p>
                      <fieldset>
                        <input
                          type='text'
                          className='input'
                          placeholder={
                            membershipCode
                              ? 'Using Member Code'
                              : 'Gift Card or Coupon Code'
                          }
                          name='couponCode'
                          value={couponCode}
                          onChange={this._inputOnChange}
                          disabled={membershipCode !== ''}
                        />
                        <button
                          className='button button--small'
                          onClick={this._applyOnClick}
                          disabled={membershipCode !== ''}
                        >
                          Apply
                        </button>
                      </fieldset>
                    </div>
                    <div className='checkout__coupons__options'>
                      <fieldset>
                        <button
                          type='button'
                          className='button'
                          onClick={this._openDialogOnClick}
                        >
                          Enter Membership Code
                        </button>
                        <dialog id='membership-code-dialog'>
                          <div className='dialog-container'>
                            <h2 className='text-center'>
                              Apply Membership Code
                            </h2>
                            <p>Enter your membership number.</p>
                            <div className='form-group'>
                              <input
                                type='text'
                                className='input'
                                autoComplete='chrome-off'
                                placeholder='Membership Code'
                                name='membershipCode'
                                value={membershipCode}
                                onChange={this._inputOnChange}
                              />
                              <button
                                className='button'
                                onClick={this._applyOnClick}
                              >
                                Apply
                              </button>
                            </div>
                            <p>
                              Not a member?{' '}
                              <a
                                className='link'
                                href='/cook-street-membership/'
                              >
                                Learn more here.
                              </a>
                            </p>
                            <button
                              className='button button--light'
                              type='button'
                              onClick={this._closeDialogOnClick}
                            >
                              Close
                            </button>
                          </div>
                        </dialog>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
              <div>{this.state.cartData.member?.firstName}</div>
              <form
                className='form'
                onSubmit={this._formOnSubmit}
                name='infoForm'
              >
                <FormTitleBlock title='Billing Details' />
                <FormInputBlock
                  name='firstName'
                  label='First Name'
                  value={firstName}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormInputBlock
                  name='lastName'
                  label='Last Name'
                  value={lastName}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormInputBlock
                  name='email'
                  label='Email'
                  type='email'
                  value={email}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormInputBlock
                  name='phone'
                  label='Phone'
                  value={phone}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormInputBlock
                  type='area'
                  name='note'
                  label='Food Allergies or Other Notes. Sitting with a friend? Please add their name here.'
                  value={note}
                  onChange={this._inputOnChange}
                />
                <FormTitleBlock title='Address' />
                <FormInputBlock
                  name='addressLine1'
                  label='Street Address'
                  value={addressLine1}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormInputBlock
                  name='addressLine2'
                  label='Street Address Line 2'
                  value={addressLine2}
                  onChange={this._inputOnChange}
                />
                <FormInputBlock
                  name='city'
                  label='City'
                  value={city}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormInputBlock
                  name='state'
                  label='State'
                  value={state}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormInputBlock
                  name='zip'
                  label='Postal / Zip Code'
                  additionalClass='input--short'
                  value={zip}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <FormSelectBlock
                  name='country'
                  label='Country'
                  options={countries}
                  defaultValue='United States'
                  value={country}
                  onChange={this._inputOnChange}
                  required={true}
                />
                <div className='label__container'></div>
                <label>
                  <input
                    type='checkbox'
                    name='newsletter'
                    onChange={this._newsletterOnSubscribe}
                    checked={newsletter}
                  />{' '}
                  Subscribe to our newsletter
                </label>

                {data.totalPriceDiscounted <= 0 && (
                  <FormSubmitBlock
                    disabled={isSubmitting}
                    label={isSubmitting ? 'Please wait...' : 'Submit'}
                  />
                )}
              </form>
              {data.totalPriceDiscounted > 0 && (
                <div className='form'>
                  <PaymentForm
                    applicationId={config.squareAppId}
                    locationId={config.squareLocationId}
                    cardTokenizeResponseReceived={this.handlePaymentMethod}
                    // cardTokenizeResponseReceived={async (token, buyer) => {
                    // 	alert(JSON.stringify(buyer, null, 2));
                    // }}
                    // onNonceGenerated={this.handlePaymentMethod}
                    // onNonceError={this.onNonceError}
                    // inputClass='input'
                    // inputStyles={[{ fontSize: '15px', lineHeight: '40px' }]}
                  >
                    <>
                      <FormTitleBlock title='Payment Information' />
                      <div className='label__container'></div>
                      <div className='input__container payment_input__container'>
                        <CreditCard
                          buttonProps={{
                            isLoading: this.state.payButtonLoadingState,
                            onClick: () =>
                              this.setState({ payButtonLoadingState: true }),
                          }}
                        />
                      </div>
                    </>
                  </PaymentForm>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
