import React, { Component } from 'react';

export default class FormInputBlock extends Component {
  render() {
    let {
      label,
      labelInfo,
      prefix,
      type,
      name,
      required,
      placeholder,
      additionalClass,
      onChange,
      value,
      disabled = false,
    } = this.props;

    if (!value) value = '';
    if (!type) type = 'text';

    return (
      <fieldset className='fieldset'>
        <div className='label__container'>
          <label className='label'>
            {label}
            {required && <span className='input__required'>*</span>}
          </label>
          {labelInfo && <p className='label__info'>{labelInfo}</p>}
        </div>
        <div className='input__container'>
          {prefix && <div className='input__prefix'>{prefix}</div>}
          {type === 'area' && (
            <textarea
              className={`input input--area ${
                additionalClass ? additionalClass : ''
              }`}
              type={type}
              disabled={disabled}
              name={name}
              placeholder={placeholder}
              required={required}
              value={value}
              onChange={onChange}
            />
          )}

          {type !== 'area' && (
            <input
              className={`input ${additionalClass ? additionalClass : ''}`}
              type={type}
              name={name}
              placeholder={placeholder}
              required={required}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        </div>
      </fieldset>
    );
  }
}
