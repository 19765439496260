import React from 'react';

export default function BlogPost({ post }) {
  return (
    <>
      {/* <h1>blog template/component.</h1> */}
      <div
        style={{
          backgroundImage: `url(https://d2uwrku0rsqwt1.cloudfront.net/large/media/${post.photo})`,
        }}
        className='blog-hero-img'
      >
        <div className='blog-hero-box'>
          <span className='tag2'>{post.tag}</span>
          <h1>{post.subtitle}</h1>
        </div>
      </div>
      <div className='blog-content-layout content'>
        <div className='blog-post-body-section'>
          <h1 className='blog-post-header'>{post.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
        </div>
      </div>
    </>
  );
}
