import React, { Component } from 'react';
import { Seo } from '../elements';
import JotFormReact from 'jotform-react';

export default class Application extends Component {
  render() {
    const formId =
      this.props.location.pathname.indexOf('culinary-arts-programs') !== -1
        ? '72685544414158'
        : '213465249053152';

    return (
      <div className='page__content'>
        <Seo title='Application' />
        <div className='content'>
          <JotFormReact formURL={`https://form.jotformeu.com/${formId}`} />
        </div>
      </div>
    );
  }
}
