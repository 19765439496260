import React, { Component } from 'react';
import API from '../api';

export default class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    await API.get('', {
      params: {
        action: 'staff',
      },
    })
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { categories } = this.state;

    return (
      <div className='page__content'>
        <div
          className='cover'
          style={{ backgroundImage: 'url(/assets/covers/classes.png)' }}
        >
          <div className='cover__shade' />
          <div className='cover__content'>
            <h1 className='cover__title'>Staff</h1>
          </div>
        </div>
        <br />
        <br />
        {categories && (
          <>
            {categories.map((category, key) => (
              <>
                <article key={key} className='article--page'>
                  <h2>{category.title}</h2>
                </article>
                <div className='categorylist'>
                  {category.staff.map((item, index) => (
                    <div key={item.id} className='categorylist__item'>
                      <div className='categorylist__item__photo'>
                        <div
                          className='photo'
                          style={{
                            backgroundImage: `url(${item.thumbnailPhoto})`,
                          }}
                        />
                      </div>
                      <div className='categorylist__item__content'>
                        <h2
                          className='categorylist__item__title'
                          dangerouslySetInnerHTML={{ __html: item.name }}
                        />
                        <h3
                          className='categorylist__item__subtitle'
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                        <div className='categorylist__item__description'>
                          <article
                            className='article'
                            dangerouslySetInnerHTML={{ __html: item.bio }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ))}
          </>
        )}
      </div>
    );
  }
}
