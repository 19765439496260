import axios from 'axios';
import { config } from './config';

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

axiosInstance.interceptors.request.use(
  function (config) {
    let data = config.data ? config.data : {};

    if (localStorage.getItem('token')) {
      data['token'] = localStorage.getItem('token');
    }

    config.data = data;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
