import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Social } from '../elements';
import { ContentContext } from '../context';
import Cookies from 'universal-cookie';

export default class Footer extends Component {
  constructor(props) {
    super(props);

    const cookies = new Cookies();

    this.state = {
      showCart: false,
      checkCartInterval: null,
      admin: cookies.get('isAdmin'),
    };
  }

  componentDidMount() {
    this.setState({
      checkCartInterval: setInterval(this.checkCart, 3000),
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.checkCartInterval);
  }

  checkCart = () => {
    let showCart = false;

    if (
      window.location.pathname !== '/checkout/' &&
      localStorage.getItem('cart')
    ) {
      showCart = true;
    }

    this.setState({
      showCart: showCart,
    });
  };

  render() {
    const { showCart, admin } = this.state;

    return (
      <ContentContext.Consumer>
        {({ getContent }) => (
          <footer className='footer'>
            <div className='wrap'>
              <div className='footer__section'>
                <h3 className='footer__title'>
                  {getContent('footerHeadquarters').title}
                </h3>
                <div
                  className='footer__content'
                  dangerouslySetInnerHTML={{
                    __html: getContent('footerHeadquarters').content,
                  }}
                />
              </div>
              <div className='footer__section'>
                <h3 className='footer__title'>
                  {getContent('footerWorkTime').title}
                </h3>
                <div
                  className='footer__content'
                  dangerouslySetInnerHTML={{
                    __html: getContent('footerWorkTime').content,
                  }}
                />
              </div>
              <div className='footer__section'>
                <h3 className='footer__title'>
                  {getContent('footerContact').title}
                </h3>
                <div
                  className='footer__content'
                  dangerouslySetInnerHTML={{
                    __html: getContent('footerContact').content,
                  }}
                />
              </div>
            </div>
            <Social />
            {showCart && (
              <div className='cart-preview pulse'>
                <NavLink to='/checkout/'>
                  <img alt='' src='/assets/icons/cart.svg' />
                </NavLink>
              </div>
            )}
            {admin && (
              <div className='admin'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://backend.cookstreet.com/'
                >
                  Go to Admin
                </a>
              </div>
            )}
          </footer>
        )}
      </ContentContext.Consumer>
    );
  }
}
