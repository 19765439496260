import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../config';

export default function Seo({ title, description }) {
  const generateTitle = () => {
    if (title && title.length > 49) return title;

    if (title) return title + ' - ' + config.title;
    return config.title;
  };

  return (
    <Helmet>
      <title>{generateTitle()}</title>
      {description && <meta name='description' content={description} />}
    </Helmet>
  );
}
