import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Carousel extends Component {
  constructor(props) {
    super(props);

    let slideInterval = setInterval(this.nextSlide, 6000);

    this.state = {
      currentSlide: 0,
      slideInterval: slideInterval,
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.slideInterval);
  }

  onPageClick = (event) => {
    let slideIndex = event.target.dataset.index;

    this.setState({
      currentSlide: Number(slideIndex),
    });

    clearInterval(this.state.slideInterval);
  };

  nextSlide = () => {
    this.setState({
      currentSlide:
        this.state.currentSlide === this.props.slides.length - 1
          ? 0
          : this.state.currentSlide + 1,
    });
  };

  render() {
    return (
      <div className='carousel__container'>
        <div
          className='carousel__slides'
          style={{
            width: `${100 * this.props.slides.length}%`,
            transform: `translateX(-${
              (this.state.currentSlide * 100) / this.props.slides.length
            }%)`,
          }}
        >
          {this.props.slides.map((item, index) => (
            <div key={index} className='carousel__slide'>
              <div
                className='carousel__content'
                style={{ backgroundImage: `url(${item.largePhoto})` }}
              >
                <div className='carousel__shade'>
                  <h2
                    className='carousel__title'
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  ></h2>
                  {item.link && (
                    <div>
                      {item.link.indexOf('http') === 0 ? (
                        <a
                          href={item.link}
                          className='button button--header color3'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {item.cta}
                        </a>
                      ) : (
                        <Link
                          to={item.link}
                          className='button button--header color3'
                        >
                          {item.cta}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='carousel__pages'>
          {this.props.slides.map((item, index) => (
            <span
              key={index}
              className={
                'carousel__page' +
                (this.state.currentSlide === index
                  ? ' carousel__page--active'
                  : '')
              }
              data-index={index}
              onClick={this.onPageClick}
            ></span>
          ))}
        </div>
      </div>
    );
  }
}
