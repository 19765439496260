import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { BookingButton, CoverPage, Loading, Seo } from '../elements';
import API from '../api';

const localizer = momentLocalizer(moment);

export default class CalendarPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      page: null,
      selected: null,
    };
  }

  componentDidMount() {
    this.loadItems();
  }

  loadItems = async () => {
    let page = this.props.location.pathname.substring(1);

    await API.get('', {
      params: {
        action: 'page',
        slug: page,
      },
    })
      .then((response) => {
        this.setState({
          page: response.data,
        });
      })
      .catch((error) => {});

    await API.get('', {
      params: {
        action: 'events',
      },
    })
      .then((response) => {
        let data = response.data;

        for (let i in data) {
          data[i].timeUntil = moment
            .duration(moment.utc(data[i].startsAt).diff(moment.utc()))
            .asHours();
          data[i].duration = moment
            .duration(
              moment.utc(data[i].endsAt).diff(moment.utc(data[i].startsAt))
            )
            .asHours();
          data[i].startsAt = moment.utc(data[i].startsAt).toDate();
          data[i].endsAt = moment.utc(data[i].endsAt).toDate();

          if (['private-event', 'class'].indexOf(data[i].eventType.slug) >= 0) {
            data[i].title =
              moment
                .utc(data[i].startsAt)
                .format(data[i].startsAt.getMinutes() ? 'h:mma' : 'ha') +
              ' - ' +
              data[i].title;
          }

          if (data[i].soldOut) {
            data[i].title = 'SOLD OUT: ' + data[i].title;
          }
        }

        this.setState({
          events: data,
        });
      })
      .catch((error) => {});
  };

  _eventOnSelect = (event) => {
    let selected = event;

    switch (selected.eventType.slug) {
      case 'private-event':
        selected.thumbnailPhoto = '/assets/images/private-event.jpg';
        selected.link = '/private-events/';
        break;
      case 'class':
        selected.thumbnailPhoto = event.class.thumbnailPhoto;
        selected.link = `/product/${event.class.slug}/`;
        break;
      case 'culinary-program':
        selected.thumbnailPhoto = '/assets/images/culinary-program.jpg';
        selected.link = '/culinary-arts-programs/';
        break;
      default:
        return false;
    }

    this.setState({
      selected: selected,
    });
  };

  _eventOnLoad = (event, start, end, isSelected) => {
    let backgroundColor = '#20639b';
    let opacity = 0.6;

    switch (event.eventType.slug) {
      case 'class':
        backgroundColor = '#20639b';
        break;
      case 'private-event':
        backgroundColor = '#ef6d57';
        break;
      case 'culinary-program':
        backgroundColor = '#3caea3';
        break;
      case 'community-event':
        backgroundColor = '#ff8437';
        break;
      case 'notice':
        backgroundColor = '#888888';
        break;
      default:
        return false;
    }

    if (event.online) {
      backgroundColor = '#d15757';
    }

    if (event.timeUntil > 0) {
      opacity = 1;
    }

    return {
      style: {
        backgroundColor: backgroundColor,
        borderRadius: '0px',
        border: '0px',
        color: '#fff',
        display: 'block',
        opacity: opacity,
      },
    };
  };

  _eventOnClose = (event) => {
    this.setState({
      selected: null,
    });
  };

  render() {
    const { page, events, selected } = this.state;

    if (!page) {
      return <Loading />;
    }

    return (
      <CoverPage title={page.title} coverPhoto={page.largePhoto}>
        <div className='calendar'>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor='startsAt'
            endAccessor='endsAt'
            onSelectEvent={this._eventOnSelect}
            eventPropGetter={this._eventOnLoad}
            views={['month']}
            longPressThreshold={10}
          />
        </div>
        <Seo title='Calendar' />
        {selected && (
          <div className='event__container'>
            <div className='event'>
              <div className='event__close' onClick={this._eventOnClose}>
                ✕
              </div>
              <div className='event__content'>
                {selected.thumbnailPhoto && (
                  <div
                    className='event__photo'
                    style={{
                      backgroundImage: `url(${selected.thumbnailPhoto})`,
                    }}
                  />
                )}
                <div className='event__description'>
                  <h2>{selected.title}</h2>
                  {selected.duration >= 20 && (
                    <h4>{moment.utc(selected.startsAt).format('MMMM Do')}</h4>
                  )}
                  {selected.duration < 20 && (
                    <h4>
                      {moment.utc(selected.startsAt).format('MMMM Do @ h:mma')}{' '}
                      - {moment.utc(selected.endsAt).format('h:mma')}
                    </h4>
                  )}
                  <article
                    className='article'
                    dangerouslySetInnerHTML={{ __html: selected.description }}
                  />
                  {selected.eventType.slug === 'class' && (
                    <h4>Price: ${selected.class.price}</h4>
                  )}
                  <div className='event__options'>
                    {selected.eventType.slug === 'class' &&
                      selected.type !== 'series' &&
                      !selected.closed &&
                      !selected.soldOut && (
                        <BookingButton
                          label='Book Now'
                          event={selected}
                          history={this.props.history}
                        />
                      )}
                    {selected.link && (
                      <NavLink
                        className='button button--light'
                        to={selected.link}
                      >
                        More Info
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CoverPage>
    );
  }
}
