import React, { useState, useEffect } from 'react';
import BlogPost from '../elements/BlogPost';
import API from '../api';
import { Loading } from '../elements';
import TableTalkPostsGrid from '../elements/TableTalkPostsGrid';

export default function TableTalkPost(props) {
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState({});
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const { slug } = props.match.params;

      await API.get('', {
        params: {
          action: 'tableTalkGetAll',
        },
      })
        .then((res) => {
          let items = res.data.filter((post) => {
            if (slug === post.link) {
              setPost(post);
            }
            if (slug !== post.link) {
              return post;
            }
            return null;
          });
          setRelatedPosts(items);
          setLoading(false);
          return;
        })
        .catch((err) => console.log(err));
    };
    loadData();
  }, [props.match.params]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div>
          <BlogPost post={post} />
          <TableTalkPostsGrid posts={relatedPosts} header={'Related Posts'} />
        </div>
      )}
    </>
  );
}
