import React, { Component } from 'react';

export default class BookingButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: this.props.event,
      isBooking: false,
    };
  }

  _bookOnClick = () => {
    let cart = JSON.parse(localStorage.getItem('cart'));
    let event = this.state.event;
    event.onlineBooking = this.props.online ? true : false;

    console.log('BOOKING');
    console.log(event);

    if (cart && cart.events) {
      let foundEvent = false;

      for (let i in cart.events) {
        if (cart.events[i].id === event.id) {
          foundEvent = true;
          break;
        }
      }

      if (!foundEvent) {
        cart.events.push(event);
      }
    } else {
      cart = cart && typeof cart === 'object' ? cart : {};
      cart.events = [];
      cart.events.push(event);
    }

    localStorage.setItem('cart', JSON.stringify(cart));

    this.props.history.push('/checkout');
  };

  componentDidUpdate(prevProps) {
    if (this.props.event !== prevProps.event) {
      this.setState({
        event: this.props.event,
      });
    }
  }

  render() {
    const { label, online } = this.props;

    return (
      <a
        className={`button ${online ? 'button--online' : ''}`}
        onClick={this._bookOnClick}
      >
        {label}
      </a>
    );
  }
}
